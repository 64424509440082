import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../redux/reducer';
import { toggleToast } from '../redux/reducer/toast.reducer';
import { AnyAction } from 'redux';

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";


interface ToastProviderProps {
    children: ReactNode;
}

function ToastProvider(props: ToastProviderProps) {

    const dispatch = useDispatch();
    const show = useSelector((state: RootState) => state.toast.show);
    const delay = useSelector((state: RootState) => state.toast.delay);
    const bg = useSelector((state: RootState) => state.toast.bg);
    const title = useSelector((state: RootState) => state.toast.title);
    const body = useSelector((state: RootState) => state.toast.body);

    return <>
        {props.children}

        <ToastContainer
            className="p-3"
            position="top-end"
            style={{
                zIndex: 999999,
                position: "fixed",
            }}
        >
            <Toast
                autohide
                onClose={() => dispatch(toggleToast(null) as unknown as AnyAction)}
                show={show}
                delay={delay}
                bg={bg}
            >
                <Toast.Header closeButton={false}>
                    <img src="/logo32.png" className="rounded me-2" alt="" />
                    <strong className="me-auto">VEROX</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body>
                    <h6 className={`text-capitalize ${bg !== "light" ? "text-gray-100" : ""}`}>
                        {title}
                    </h6>
                    <span className={bg !== "light" ? "text-white" : ""}>
                        {body}
                    </span>
                </Toast.Body>
            </Toast>
        </ToastContainer>

    </>
}

export default ToastProvider;