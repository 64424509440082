import { Card, Col, Image, Modal, Nav, Row } from "@themesberg/react-bootstrap";
import {
  faMedium,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import { PagesRoutes } from "../../common/routes";
import VeroxLogo from "../../assets/images/logo.png";
import apple from "../../assets/images/apple.png";
import gp from "../../assets/images/google-play.png";
import phone from "../../assets/images/phone_2.png";
import withCustomRouter, { CustomRouteComponentProps } from "../../common/withCustomRouter";

interface FooterState {
  isContactUsVisible: boolean;
}

interface FooterProps extends CustomRouteComponentProps { }

class Footer extends React.Component<FooterProps, FooterState> {
  constructor(props: FooterProps) {
    super(props);
    this.state = {
      isContactUsVisible: false,
    };
  }

  showContactUs() {
    this.setState({ isContactUsVisible: true });
  }

  hideContactUs() {
    this.setState({ isContactUsVisible: false });
  }

  faqLink = () => {
    if (this.props.location.pathname !== PagesRoutes.FAQ.path)
      return <NavItem link={PagesRoutes.FAQ.path} title="FAQ" />;

    return "";
  }

  render() {
    return (
      <div className="footers">
        <>
          <div className="footer-mobile-app">
            <Row>
              <Col xs={12} lg={6}>
                <div className="side-app-text">
                  <h4>
                    Experience the power of VeroxAI in the palm of your hand.
                  </h4>
                  <p className="download-text">
                    Get VeroxAI's insights and analytics on the go. Download the VeroxAI app today.
                  </p>
                  <h4>
                    Download Now!
                  </h4>
                  <div className="playstr-img">
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/veroxai/id1572202642"
                      rel="noreferrer"
                    >
                      <img src={apple} alt="apple" />
                    </a>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.verox.ai"
                      rel="noreferrer"
                    >
                      <img src={gp} alt="android" />
                    </a>
                  </div>
                </div>
              </Col>

              <Col md="auto" xs lg="6">
                <div className="img-app">
                  <img src={phone} alt="app" className="right" />
                </div>
              </Col>
            </Row>
          </div>
        </>
        <footer className="footer section py-5">
          <Row>
            <Col xs={12} lg={3} className="mb-4 mb-lg-0">
              <div className="FooterWidgetTitle">About</div>
              <div className="footer-menu">
                <Nav className="flex-column pt-3 pt-md-0 menu">
                  <NavItem title="About Us" link={PagesRoutes.AboutUs.path} />
                  <NavItem
                    title="Cookie Policy"
                    link={PagesRoutes.CookiePolicy.path}
                  />
                  <NavItem title="Terms Of Use" link={PagesRoutes.TermsOfUse.path} />
                  <NavItem
                    title="Privacy Policy"
                    link={PagesRoutes.PrivacyPolicy.path}
                  />
                  <NavItem title="Disclaimer" link={PagesRoutes.Disclaimer.path} />
                </Nav>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div className="FooterWidgetTitle">Tools</div>
              <div className="footer-menu">
                <Nav className="flex-column pt-3 pt-md-0 menu">
                  <NavItem title="Admin Calls" link={PagesRoutes.AdminCalls.path} />
                  {/* <NavItem
                    title="Score Analyzer"
                    link={PagesRoutes.ScoreAnalyser.path}
                  /> */}
                  <NavItem
                    title="Biggest Volume Changes"
                    link={PagesRoutes.BiggestVolumeChanges.path}
                  />
                  <NavItem title="Top Picks" link={PagesRoutes.TopPicks.path} />
                  <NavItem
                    title="Passive Income"
                    link={PagesRoutes.PassiveIncome.path}
                  />
                </Nav>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div className="FooterWidgetTitle">Support</div>
              <div className="footer-menu">
                <Nav className="flex-column pt-3 pt-md-0 menu">
                  <NavItem
                    title="Contact Us"
                    onClick={() => this.showContactUs()}
                    link="#"
                  />
                  {this.faqLink()}
                </Nav>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div className="FooterWidgetTitle">Follow</div>
              <div className="footer-menu">
                <ul className="social-media">
                  <li>
                    <a href="https://twitter.com/verox_aicrypto?s=11">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="https://veroxai.medium.com/">
                      <FontAwesomeIcon icon={faMedium} />
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/veroxgroup">
                      <FontAwesomeIcon icon={faTelegram} />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="footer-2">
            <p className="mb-0 text-center text-xl-left">
              {/* © {`${currentYear} `} */}
              <Card.Link
                href="https://veroxai.com/"
                target="_blank"
                className="text-decoration-none fw-normal"
              >
                Verox AI
              </Card.Link>
              &nbsp;&nbsp;&copy;&nbsp; 2023
            </p>
          </Row>
          {/* <Signup
                modalOpen={modalOpen}
                handleModalOpen={handleModalOpen} /> */}
        </footer>
        <Modal
          className="auth-modal"
          size="lg"
          centered
          show={this.state.isContactUsVisible}
          backdrop="static"
          onHide={() => this.hideContactUs()}
        >
          <Modal.Header closeButton>
            <Image src={VeroxLogo} />
          </Modal.Header>
          <Modal.Body>
            <h3 className="contact-text">
              Please send your queries to team@veroxai.com
            </h3>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function NavItem(props: any) {
  const { title, link, target, badgeText, onClick } = props;
  const classNames = badgeText
    ? "d-flex justify-content-start align-items-center justify-content-between"
    : "";

  return (
    <Nav.Item onClick={onClick}>
      {/* onClick={() => setShow(false)}> */}
      <Nav.Link as={Link} to={link} target={target} className={classNames}>
        <span>
          <span className="sidebar-text">{title}</span>
        </span>
      </Nav.Link>
    </Nav.Item>
  );
}

export default withCustomRouter(Footer);
