import { createContext, useEffect, useState } from "react";

const ThemeContext = createContext()

export const ThemeProvider = (props) => {
    const [dark, setDark] = useState(false)

    const changeTheme = (state) => {
        localStorage.setItem("__veroxTheme__", JSON.stringify({ dark: state }))
        setDark(state)
    }
    useEffect(() => {
        const themeType = JSON.parse(localStorage.getItem("__veroxTheme__"))
        if (themeType) {
            setDark(themeType.dark)
        }
    }, [])

    return (
        <ThemeContext.Provider value={{ dark, changeTheme }}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export default ThemeContext