const Loader = (props: any) => {
  return (<>

    <div className="loading">
      <div></div>
      <div></div>
    </div>
  </>
  );
};

export default Loader;
