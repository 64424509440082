import { useNavigate, Link, NavLink } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { Badge, Button, Image, Nav, Navbar } from "@themesberg/react-bootstrap";
import { Switch } from "@material-ui/core";
import { NavDropdown } from "react-bootstrap";


import { IoMdNotifications } from "react-icons/io";

import { PagesRoutes } from "../../common/routes";
import { openLoginModal } from "../../redux/reducer/auth.reducer";

import { PlanNameHelper, ScopeTypes } from "../../utils/constants";
import ScopedAccess from "../../common/ScopedAccess";

import LogoWebsite from "../../assets/images/logo_4x.png";
import ConnectWallet from "../../common/ConnectWallet";


const MyNavBar = (props: any) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const unreadNotificationCount = useSelector((state: any) => state.notifications.unreadNotificationCount);

    const { dark, user, logout, changeTheme, toggleSidebarOpen } = props;

    return <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        className={`navbar_____new navbar_____new-head navbar-theme-primary px-4 ${dark ? "bg-dark" : ""}`}
    >
        {
            !!user && <span className="toggle-sidebar" onClick={toggleSidebarOpen}>
                <i className="fa fa-bars fa-2x" />
            </span>
        }
        <Navbar.Brand className="me-lg-5" as={Link} to={PagesRoutes.Markets.path}>
            <Image src={LogoWebsite} className="navbar-brand-light" />
        </Navbar.Brand>
        {
            !!user && (<>
                <Navbar.Toggle as={"span"} aria-controls="right-side-menu">
                    <i className="fa fa-cog fa-2x" />
                </Navbar.Toggle>
                <Navbar.Collapse id="right-side-menu" className="justify-content-end">
                    <Nav.Item>
                        <p className="buy-vrx-button p-0">
                            <a rel="noreferrer" className="text-decoration-none text-white d-inline-block w-100 h-100 px-4 py-2" href="https://app.uniswap.org/#/swap?inputCurrency=0x87de305311d5788e8da38d19bb427645b09cb4e5" target="_blank">Buy VRX</a>
                        </p>
                    </Nav.Item>
                    <Nav.Item>
                        <p className="p-0">
                            <NavLink to="/paymentplan" className="text-decoration-none text-white d-inline-block w-100 h-100 px-4 py-2" >Membership</NavLink>
                        </p>
                    </Nav.Item>
                    <Nav.Item>
                        <div className="nav-item-btn p-0">
                            <NavDropdown
                                title={
                                    <span className="align-items-center justify-content-between d-flex user-dropdown-title">
                                        {user?.email || ""}
                                        <div className="bg-gradient-dark badge rounded-pill text-uppercase p-2 ml-2">
                                            {PlanNameHelper[user?.plan || "no_plan"]}
                                        </div>
                                        {
                                            unreadNotificationCount > 0 && <div className="bg-white badge rounded-pill p-1 ml-2">
                                                <IoMdNotifications size={"1.5em"} color={"red"} />
                                            </div>
                                        }
                                    </span>
                                }
                                id="user-nav-dropdown"
                            >
                                <ScopedAccess plan={user?.plan} scopeType={ScopeTypes.FAVOURITES}>
                                    <NavDropdown.Item onClick={() => { navigate(PagesRoutes.MyFavourites.path) }}>
                                        <i className="fa fa-heart mr-2" /> Favourites
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </ScopedAccess>

                                <ScopedAccess plan={user?.plan} scopeType={ScopeTypes.ALERTS}>
                                    <NavDropdown.Item onClick={() => { navigate(PagesRoutes.MyAlerts.path) }}>
                                        <i className="fa fa-bell mr-2" /> Alerts
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </ScopedAccess>

                                <NavDropdown.Item onClick={() => { navigate(PagesRoutes.Notifications.path) }}>
                                    <i className="fa fa-envelope mr-2" />
                                    Notifications
                                    {
                                        unreadNotificationCount > 0 && <Badge
                                            pill
                                            className="badge-md ms-2 text-white bg-buy-vrx p-2"
                                            bg={"secondary"}
                                        >
                                            {unreadNotificationCount > 99 ? "99+" : unreadNotificationCount}
                                        </Badge>
                                    }
                                </NavDropdown.Item>

                                <NavDropdown.Divider />

                                <NavDropdown.Item onClick={() => { navigate(PagesRoutes.Settings.path) }}>
                                    <i className="fa fa-cog mr-2" /> Settings
                                </NavDropdown.Item>

                                <NavDropdown.Divider />

                                <NavDropdown.Item onClick={() => logout()}>
                                    <i className="fa fa-sign-out-alt mr-2" />
                                    Logout
                                </NavDropdown.Item>

                            </NavDropdown>
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <div className="nav-item-btn p-0">
                            <ConnectWallet isSidebar payAmount={null} />
                        </div>
                    </Nav.Item>
                    <Nav.Item>
                        <span className="d-flex align-items-center">
                            <i className={`fa fa-sun fa-2x ${!dark ? "text-warning" : "text-gray"}`}></i>
                            <Switch
                                checked={dark}
                                onChange={(e) => {
                                    changeTheme(e.target.checked)
                                }}
                            />
                            <i className={`fa fa-moon fa-2x ${dark ? "text-warning" : "text-gray"}`}></i>
                        </span>
                    </Nav.Item>
                </Navbar.Collapse>
            </>)
        }
        {
            !user && (<>
                <Button
                    variant="primary"
                    className="login-button"
                    onClick={(e) => {
                        e?.preventDefault();
                        dispatch(openLoginModal())
                    }}
                >
                    Login
                </Button>
            </>)
        }
    </Navbar>
}

export default MyNavBar;