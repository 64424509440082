import { matchPath, useLocation } from 'react-router-dom';
import { PagesRoutes } from './routes';

export function useMatchedRoute() {
    const { pathname } = useLocation();
    for (const route of (Object.entries(PagesRoutes)).map(data => data[1])) {
        if (matchPath({ path: route.path }, pathname)) {
            return route;
        }
    }
}