import React from 'react';
import {
  Image,
  Navbar,
} from "@themesberg/react-bootstrap";

import ReactSidebar from "react-sidebar";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import LogoWebsite from "../../assets/images/logo_4x.png";
import { PagesRoutes } from "../../common/routes";
import SimpleBar from "simplebar-react";
import ThemeContext from "../../theme/themeContext";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ConnectedProps, connect } from 'react-redux';
import MenuItems from './MenuItems';
import { RootState } from '../../redux/reducer';
import { logout } from '../../redux/reducer/auth.reducer';
import MyNavBar from './MyNavBar';

function getLibrary(provider: any) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

interface SidebarState {
  mobileSidebar: boolean;
  dark: boolean;
  sidebarOpen: any;
}

interface SidebarProps extends PropsFromRedux {

}

class Sidebar extends React.Component<SidebarProps, SidebarState> {
  constructor(props: SidebarProps) {
    super(props);
    this.state = {
      mobileSidebar: false,
      dark: false,
      sidebarOpen: false,
    };
  }

  sidebar = () => {
    const navLink = document.querySelectorAll(".sidebar .nav-item .nav-link");
    const navLinkA = document.querySelectorAll(".sidebar .nav-item.active .nav-link");
    const navItemA = document.querySelectorAll(".sidebar .nav-item.active::before");
    const sidebarFa = document.querySelectorAll(".sidebar .fa");
    const sidebarFaA = document.querySelectorAll(".sidebar .nav-item.active > .nav-link .sidebar-icon .fa");
    const sidebarFas = document.querySelectorAll(".sidebar .fas");
    const sidebarFasA = document.querySelectorAll(".sidebar .nav-item.active > .nav-link .sidebar-icon .fas");
    const sidebarText = document.querySelectorAll(
      ".sidebar .nav-link .sidebar-text"
    );
    const sidebarTextA = document.querySelectorAll(
      ".sidebar .nav-item.active > .nav-link .sidebar-text"
    );

    /* eslint-disable array-callback-return */
    if (this.context.dark) {
      Array.from(navLink as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        // link.style.background = "none";
        link.style.webkitTextFillColor = "inherit";
      });
      Array.from(sidebarFa as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        // link.style.background = "none";
        link.style.webkitTextFillColor = "inherit";
      });
      Array.from(sidebarFaA as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        // link.style.background = "none";
        // link.style.webkitTextFillColor = "inherit";
        link.style.setProperty("color", "#13C7FF", "important");
      });
      Array.from(sidebarFas as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        // link.style.background = "none";
        link.style.webkitTextFillColor = "inherit";
      });
      Array.from(sidebarFasA as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        // link.style.background = "none";
        link.style.setProperty("color", "#13C7FF", "important");
      });
      Array.from(sidebarText as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        // link.style.background = "none";
        link.style.color = "#6979c5"
        link.style.webkitTextFillColor = "inherit";
      });
      Array.from(sidebarTextA as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        // link.style.background = "none";
        link.style.setProperty("color", "#13C7FF", "important");
      });
      Array.from(navLinkA as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        link.style.background = "#2e3650";
        // link.style.webkitTextFillColor = "inherit";
      });
      Array.from(navItemA as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "content-box";
        link.style.setProperty("background", "#05B4FF", "important");
        // link.style.webkitTextFillColor = "inherit";
      });
    } else {
      Array.from(navLink as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "text";
        // link.style.background = "linear-gradient(to right, #9d6bff, #ff6ccd)";
        link.style.webkitTextFillColor = "transparent";
      });
      Array.from(sidebarFa as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "text";
        // link.style.background = "linear-gradient(to right, #9d6bff, #ff6ccd)";
        link.style.webkitTextFillColor = "transparent";
      });
      Array.from(sidebarFas as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "text";
        // link.style.background = "linear-gradient(to right, #9d6bff, #ff6ccd)";
        link.style.webkitTextFillColor = "transparent";
      });
      Array.from(sidebarText as NodeListOf<HTMLElement>).map((link) => {
        // link.style.backgroundClip = "text";
        // link.style.background = "linear-gradient(to right, #9d6bff, #ff6ccd)";
        link.style.webkitTextFillColor = "transparent";
      });
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {

    // if (prevState.dark !== this.context.dark) {
    const root = document.documentElement;
    let variables = getComputedStyle(root);
    this.sidebar();
    if (this.context.dark) {
      root?.style.setProperty("--bs-body-bg", "#040a16");
      root?.style.setProperty("--bs-primary-rgb", "5,11,35");
      root?.style.setProperty("--bs-dark-rgb", "5,11,35");
      root?.style.setProperty("--bs-dark", "#050b23");
      root?.style.setProperty("--footer-bg", "#030c29");
      root?.style.setProperty(
        "--text-gradient",
        "linear-gradient(to right, #1f0052, #510030)"
      );
      root?.style.setProperty("--theme-text-clip", "inherit");
    } else {
      root?.style.setProperty(
        "--bs-body-bg",
        variables.getPropertyValue("--bs-soft")
      );
      root?.style.setProperty("--bs-primary-rgb", "38,45,68");
      root?.style.setProperty("--bs-dark-rgb", "38,45,68");
      root?.style.setProperty("--bs-dark", "#262D44");
      root?.style.setProperty("--footer-bg", "#323b58");
      root?.style.setProperty(
        "--text-gradient",
        "linear-gradient(to right, #9d6bff, #ff6ccd)"
      );
      root?.style.setProperty("--theme-text-clip", "text");
    }
  }

  toggleSidebarOpen = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    })
  }

  render() {

    const { sidebarOpen } = this.state;

    return (
      <Web3ReactProvider getLibrary={getLibrary}>
        <ReactSidebar
          sidebarClassName='react_____sidebar'
          sidebar={
            <MenuItems
              plan={this.props.user?.plan}
              BrandComponent={
                <Navbar.Brand className="align-items-center d-flex justify-content-between mb-3 px-3">
                  <Link to={PagesRoutes.Markets.path}>
                    <Image src={LogoWebsite} className="navbar-brand-light" />
                  </Link>
                  <span className="close-button" onClick={this.toggleSidebarOpen}>
                    <i className="fa fa-times"></i>
                  </span>
                </Navbar.Brand>
              }
              toggleSidebar={this.toggleSidebarOpen}
            />
          }
          open={sidebarOpen}
          onSetOpen={this.toggleSidebarOpen}
          styles={{ sidebar: {} }}
        >
          <div></div>
        </ReactSidebar>
        <MyNavBar
          dark={this.context.dark}
          user={this.props.user}
          logout={this.props.logout}
          changeTheme={this.context.changeTheme}
          toggleSidebar={() => {
            this.setState({
              ...this.state,
              mobileSidebar: !this.state.mobileSidebar,
            })
          }}
          toggleSidebarOpen={this.toggleSidebarOpen}
        />
        <CSSTransition timeout={300} classNames="sidebar-transition">
          <SimpleBar
            className={`sidebar d-md-block bg-primary text-white ${this.state.mobileSidebar ? "sidebar-mobile" : "sidebar-mobile-closed"}`}
          >
            <div className="sidebar-inner px-4 pt-3">
              <MenuItems plan={this.props.user?.plan} />
            </div>
          </SimpleBar>
        </CSSTransition>
      </Web3ReactProvider>
    );
  }
}

Sidebar.contextType = ThemeContext;

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

const mapDispatchToProps = {
  logout
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Sidebar);
