import axios from 'axios';
import { LocalStorageKeys } from '../../utils/constants';
import { clearLocalStorage } from '../../utils/utils';

export const axiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${localStorage.getItem(LocalStorageKeys.VEROX_UID)}`,
    },
});

// You can set default headers like Content-Type here
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor to set the authorization header
axiosInstance.interceptors.request.use(
    (config) => {
        // Get the token from localStorage and set the authorization header
        const token = window.localStorage.getItem(LocalStorageKeys.VEROX_UID);
        if (token) {
            config.headers['authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add an interceptor to handle 401 responses - Invalid/Expired token
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Handle the 401 response here, e.g., by redirecting to a login page
            // You can also clear the token from localStorage if needed
            console.log("Unauthorized. Redirecting to login page...");
            clearLocalStorage();
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);