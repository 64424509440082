import React from 'react';
import {
  useNavigate,
  NavigateFunction,
  useLocation,
  Location,
} from "react-router-dom";

export interface CustomRouteComponentProps {
  navigate: NavigateFunction
  location: Location
}

const withCustomRouter = (BaseComponent: React.ComponentType<any>) => {
  const EnhancedComponent: any = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    return <BaseComponent {...props} navigate={navigate} location={location} />;
  };
  return EnhancedComponent;
};

export default withCustomRouter;