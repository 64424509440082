import Footer from "../components/shared/footer";
import Sidebar from "../components/shared/sidebar";
import React from 'react';

interface BaseLayoutProps {
    children: any;
}

const BaseLayout: React.FC<BaseLayoutProps> = (props) => {

    return <>
        <Sidebar />
        <div className="app-container-with-sidebar">
            <div className="component">
                {props.children}
            </div>
            <Footer />
        </div>
    </>
}

export default BaseLayout;