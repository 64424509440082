import binanceSVG from "../assets/images/binance.svg";
import binanceUSSVG from "../assets/images/binance_us.svg";

export const APP_VERSION = "1.0.2";

export const LocalStorageKeys = {
    VEROX_APP_VERSION: "verox_app_version",
    VEROX_TOKEN: "verox_token",
    VEROX_UID: "verox_uid",
    VEROX_USER_DISPLAY_NAME: "verox_user_display_name",
    VEROX_EMAIL: "verox_email",
    VEROX_SIGNIN: "verox_signin",
    VEROX_USER: "verox_user",
}

export const ScopeTypes = {
    MARKETS: "markets",
    NEWS: "news",
    FAQ: "faq",
    CHARTS: "charts",
    CHART_SIGNALS: "chart_signals",
    FAVOURITES: "favourites",
    ALERTS: "alerts",
    Notification: "notification",
    PRICE_ALERTS: "price_alerts",
    SIGNAL_ALERTS: "signal_alerts",
    TOP_PICKS: "top_picks",
    MOST_MENTIONED: "most_mentioned",
    BIGGEST_VOLUME: "biggest_volume",
    DASHBOARD: "dashboard",
    RECENT_SIGNALS: "recent_signals",
    NFTS: "nfts",
    LOW_CAP_GEMS: "low_cap_gems",
    PLAY_TO_EARN: "play_to_earn",
    HOT_PRESALES: "hot_presales",
    AIRDROP_OPPORTUNITIES: "airdrop-opportunities",
    PASSIVE_INCOME: "passive_income",
    ADMIN_CALLS: "admin_calls",
    LONG_TERM: "long_term",
    TRADING_BOT: "trading_bot",
    MAKEALERT: "makealerts",

}

export const User_Plan = {
    NO_PLAN: "no_plan",
    STARTER: "starter",
    PRO: "pro",
    ULTIMATE: "ultimate",
}

export const PlanNameHelper: any = {
    "no_plan": "Free",
    "starter": "Starter",
    "pro": "Pro",
    "ultimate": "Ultimate",
}

export const VEROX_WALLET_ADDRESS: string = "0x03D18Ad45e15e28275969412b8B4275A11549f59";
export const VEROX_TOKEN_CONTRACT_ADDRESS: string = "0x87DE305311D5788e8da38D19bb427645b09CB4e5";

export const DEFAULT_TRADING_PLATFORM: string = "binance.com";

export const TRADING_PLATFORM_OPTIONS: any = [
    {
        id: "binance.com",
        label: "Binance.com",
        image: binanceSVG
    },
    {
        id: "binance.us",
        label: "Binance.US",
        image: binanceUSSVG
    }
]