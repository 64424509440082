import React, { ReactNode, createContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SocketIOClient from "socket.io-client";

import { RootState } from "../redux/reducer";
import { setUser, refreshToken } from "../redux/reducer/auth.reducer";
import { toggleToast } from "../redux/reducer/toast.reducer";
import { ApiEndPoints } from "../network/config/endpoints";
import { AnyAction } from "redux";


interface SocketProviderProps {
    children: ReactNode;
}

interface ProviderProps {
    webSocket: SocketIOClient.Socket | null;
    authWebSocket: SocketIOClient.Socket | null;
}

const defaultProvider: ProviderProps = {
    webSocket: null,
    authWebSocket: null,
}

const SocketContext = createContext(defaultProvider);

const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {

    const dispatch = useDispatch();

    const [webSocket, setWebSocket] = useState<SocketIOClient.Socket | null>(null);
    const [authWebSocket, setAuthWebSocket] = useState<SocketIOClient.Socket | null>(null);

    const user = useSelector((state: RootState) => state.auth.user);
    const token = useSelector((state: RootState) => state.auth.token);

    useEffect(() => {
        if (!webSocket) {
            let newWebSocket = SocketIOClient("https://coinfeed.veroxai.io");
            newWebSocket.connect();
            setWebSocket(newWebSocket);
        }

        return () => {
            if (webSocket) {
                webSocket.disconnect();
            }
        };
    }, [webSocket])

    useEffect(() => {
        if (user && token && !authWebSocket) {
            let newWebSocket = SocketIOClient(ApiEndPoints.auth.SOCKET, {
                query: {
                    token: token
                }
            });
            newWebSocket.connect();
            setAuthWebSocket(newWebSocket);

            newWebSocket.on('subscription-status', (data: any) => {
                dispatch(setUser(data));
                dispatch(toggleToast({
                    delay: 3000,
                    bg: "success",
                    title: "Membership",
                    body: "Membership plan has updated",
                }));
                dispatch(refreshToken() as unknown as AnyAction);
            })
        }

        return () => {
            if (authWebSocket) {
                authWebSocket.off("subscription-status");
                authWebSocket.disconnect();
            }
        };
    }, [authWebSocket, dispatch, token, user])

    return <SocketContext.Provider value={{ webSocket: webSocket, authWebSocket: authWebSocket }}>
        {children}
    </SocketContext.Provider >
}

export { SocketContext, SocketProvider }