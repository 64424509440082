import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getUnreadNotificationCount } from "../../network/services/notification.service";

interface NotificationsState {
    unreadNotificationCount: number;
}

// Async thunk for fetching unread notification count
export const fetchUnreadCountAsync = createAsyncThunk('notifications/fetchUnreadCount', async () => {
    try {
        const count = await getUnreadNotificationCount();
        return count as number;
    } catch (error) {
        console.error('Error fetching unread notification count:', error);
        throw error;
    }
});

const initialState: NotificationsState = {
    unreadNotificationCount: 0,
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        setUnreadCount: (state, action: PayloadAction<number>) => {
            state.unreadNotificationCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUnreadCountAsync.fulfilled, (state, action) => {
                state.unreadNotificationCount = action.payload;
            })
    },
})

export const { setUnreadCount } = notificationsSlice.actions;
export default notificationsSlice.reducer;