import React, { Suspense } from "react";

// core styles
import "./scss/verox.scss";
import "./scss/style.css";
// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";

import App from "./components/app";
import { HashRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import { ThemeProvider } from "./theme/themeContext";
import FallbackSpinner from "./components/shared/fallbackSpinner";
import VersionGuard from "./layouts/VersionGuard";
import AuthProvider from "./layouts/AuthProvider";
import ToastProvider from "./layouts/ToastProvider";
import { SocketProvider } from "./context/SocketProvider";
import ThemeComponent from "./layouts/ThemeComponent";

window.onhashchange = () => {
  // console.log("onHashChange")
  window.scrollTo(0, 0);
  window.setTimeout(() => window.scrollTo(0, 0), 200);
}

function getLibrary(provider: any) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={1500}
          preventDuplicate={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ThemeProvider>
            <ThemeComponent>
              <HashRouter>
                <Suspense fallback={<FallbackSpinner />}>
                  <VersionGuard>
                    <AuthProvider>
                      <ToastProvider>
                        <SocketProvider>
                          <App />
                        </SocketProvider>
                      </ToastProvider>
                    </AuthProvider>
                  </VersionGuard>
                </Suspense>
              </HashRouter>
            </ThemeComponent>
          </ThemeProvider>
        </SnackbarProvider>
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
