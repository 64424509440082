import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/shared/loader';

const ScopeHelper: any = {
    "no_plan": [
        "markets",
        "news",
        "faq",
        "charts",
        "favourites",
    ],
    "starter": [
        "markets",
        "news",
        "faq",
        "charts",
        "chart_signals",
        "favourites",
        "alerts",
        "notifications",
        "price_alerts",
        "signal_alerts",
        "top_picks",
        "most_mentioned",
        "biggest_volume",
        "dashboard",
        "recent_signals",
        "nfts",
        "low_cap_gems",
        "play_to_earn",
        "hot_presales",
        "airdrop-opportunities",
    ],
    "pro": [
        "markets",
        "news",
        "faq",
        "charts",
        "chart_signals",
        "favourites",
        "alerts",
        "notifications",
        "price_alerts",
        "signal_alerts",
        "top_picks",
        "most_mentioned",
        "biggest_volume",
        "dashboard",
        "recent_signals",
        "nfts",
        "low_cap_gems",
        "play_to_earn",
        "hot_presales",
        "airdrop-opportunities",
        "passive_income",
        "admin_calls",
        "long_term",
        "trading_bot",
    ],
    "ultimate": [
        "markets",
        "news",
        "faq",
        "charts",
        "chart_signals",
        "favourites",
        "alerts",
        "notifications",
        "price_alerts",
        "signal_alerts",
        "top_picks",
        "most_mentioned",
        "biggest_volume",
        "dashboard",
        "recent_signals",
        "nfts",
        "low_cap_gems",
        "play_to_earn",
        "hot_presales",
        "airdrop-opportunities",
        "passive_income",
        "admin_calls",
        "long_term",
        "trading_bot",
    ]
}

interface ScopedAccessProps {
    plan?: string;
    scopeType: string;
    childrenType?: string | null;
    fallbackPage?: string | null;
    children: ReactNode;
}

export function isScopeHigher(userPlan?: string, scopeType?: string): boolean {
    if (userPlan && scopeType) {
        const planOrder = ["no_plan", "starter", "pro", "ultimate"];

        // Get the index of the user's current plan in the plan order
        const userPlanIndex = planOrder.indexOf(userPlan);

        // Check if the scopeType is present in any higher plan
        for (let i = userPlanIndex + 1; i < planOrder.length; i++) {
            const higherPlan = planOrder[i];
            if (ScopeHelper[higherPlan].includes(scopeType)) {
                // Check if the scopeType is not in the user's current plan
                if (!ScopeHelper[userPlan].includes(scopeType)) {
                    return true;
                }
            }
        }
    }

    // Scope type is either not accessible or already available in the user's current plan
    return false;
}

const ScopedAccess: React.FC<ScopedAccessProps> = ({ plan, scopeType, children, childrenType, fallbackPage }) => {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [higherScope, setHigherScope] = useState(isScopeHigher(plan, scopeType));

    const callFallback = useCallback((fallbackPage: any) => {
        if (fallbackPage) {
            navigate(fallbackPage);
        }
    }, [navigate]);

    useEffect(() => {
        if (plan && scopeType) {
            setHigherScope(isScopeHigher(plan, scopeType));
        }
    }, [plan, scopeType])

    useEffect(() => {
        if (higherScope) {
            if (childrenType === "page") {
                callFallback(fallbackPage);
            }
        }
    }, [callFallback, childrenType, fallbackPage, higherScope])

    if (!(plan && scopeType)) return null;
    if (ScopeHelper[plan].includes(scopeType)) {
        return <>{children}</>;
    }

    if (higherScope) {
        if (childrenType === "page") {
            return <Loader />
        }

        return (
            <div
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    e.stopPropagation();
                    e.preventDefault();
                    enqueueSnackbar('Upgrade your plan!', { variant: 'warning' });
                    // Instead of opening the page, you can navigate to a different route

                }}
            >
                <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 9999 }} />
                <div>{children}</div>
            </div>
        );
    }

    return null;
};

export default ScopedAccess;