import VeroxLogo from "../../assets/images/logo_2x.png";

const FallbackSpinner = () => {
    return (
        <>
            <div style={{ height: "100vh", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                <img src={VeroxLogo} alt="Verox_logo" width="300px" />
                <div className="loading">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    );

};

export default FallbackSpinner;