import { IUser, IUserWithToken } from "../../common/interface"
import { axiosInstance } from "../config/adapter"
import { ApiEndPoints } from "../config/endpoints"
import { enqueueSnackbar } from "notistack"

export const getUser = async (): Promise<IUser> => {
    return new Promise<IUser>((resolve, reject) => {
        try {
            axiosInstance
                .get(ApiEndPoints.user.GET_DETAILS)
                .then((response) => response.data)
                .then((response) => response.data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // console.log("DEBUG Error = ", error?.response?.status)
                    // if (error?.response?.status >= 400) {
                    //     this.toggleToast({
                    //         delay: 3000,
                    //         bg: "danger",
                    //         status: "Session",
                    //         message: "Session expired",
                    //     })
                    //     this.logout();
                    // }
                    reject("Session expired")
                })

        } catch (e) {
            if (e instanceof Error) {
                enqueueSnackbar(e.message);
            } else {
                enqueueSnackbar("An error occurred.");
            }
            reject(null);
        }
    })
}

export const refreshToken = async (): Promise<IUserWithToken> => {
    return new Promise<IUserWithToken>((resolve, reject) => {
        try {
            axiosInstance
                .get(ApiEndPoints.auth.REFRESH_TOKEN)
                .then((result) => result.data)
                .then((result: any) => {
                    window.location.reload();
                    resolve({ token: result.token, ...result.data });
                })
                .catch((error: any) => {
                    // this.toggleToast({
                    //     delay: 3000,
                    //     bg: "danger",
                    //     status: "Session",
                    //     message: "Session expired",
                    // })
                    // this.logout();
                    reject("Session expired")
                });

        } catch (e) {
            if (e instanceof Error) {
                enqueueSnackbar(e.message);
            } else {
                enqueueSnackbar("An error occurred.");
            }
            reject(null);
        }
    })
}