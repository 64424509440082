import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndPoints } from "../network/config/endpoints";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/reducer";
import { toggleToast } from "../redux/reducer/toast.reducer";
import { setUser } from "../redux/reducer/auth.reducer";

// custom.d.ts
interface ExtendedWindow extends Window {
    ethereum?: any;
}

interface ConnectWalletProps {
    isSidebar: boolean;
    payAmount?: number | null;
}

const ConnectWallet: React.FC<ConnectWalletProps> = ({ isSidebar, payAmount }) => {

    // ** Redux Props
    const user = useSelector((state: RootState) => state.auth.user);

    const dispatch = useDispatch();

    // ** States
    const [isConnected, setIsConnected] = useState(false);
    const [connectedAccount, setConnectedAccount] = useState<string | null>(null);

    useEffect(() => {
        if (user) {
            if (user.wallet) {
                setIsConnected(true);
                setConnectedAccount(user.wallet);
            }
        }
    }, [user])

    const updateWalletAddress = (address: string) => {
        axiosInstance
            .post(ApiEndPoints.wallet.UPDATE, {
                wallet: address,
            })
            .then((response) => response.data)
            .then((response) => {
                let userData = response.data;
                dispatch(setUser(userData));
                setIsConnected(true);
                setConnectedAccount(userData.wallet);
                dispatch(
                    toggleToast({
                        delay: 3000,
                        bg: "success",
                        title: response.status,
                        body: response.message,
                    })
                )

                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }).catch((error) => {
                dispatch(
                    toggleToast({
                        delay: 3000,
                        bg: "danger",
                        title: error.response.data.status,
                        body: error.response.data.message,
                    })
                )
            })
    }

    async function connect() {
        if ((window as ExtendedWindow).ethereum) {
            try {
                await (window as ExtendedWindow).ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await (window as ExtendedWindow).ethereum.request({ method: 'eth_accounts' });
                const userAddress = accounts[0];

                console.log("DEBUG accounts: ", accounts)
                console.log("DEBUG userAddress: ", userAddress)

                updateWalletAddress(userAddress);

            } catch (error) {
                console.error('DEBUG Error connecting with MetaMask:', error);
            }
        } else {
            dispatch(
                toggleToast({
                    delay: 3000,
                    bg: "danger",
                    title: "Error",
                    body: "MetaMask is not installed or not accessible.",
                })
            )
            console.error('DEBUG MetaMask is not installed or not accessible.');
        }
    }

    const disconnectWalletAddress = () => {
        axiosInstance
            .get(ApiEndPoints.wallet.DISCONNECT)
            .then((response) => response.data)
            .then(async (response) => {
                let userData = response.data;
                dispatch(setUser(userData));
                setIsConnected(false);
                setConnectedAccount(userData.wallet);
                dispatch(
                    toggleToast({
                        delay: 3000,
                        bg: "success",
                        title: response.status,
                        body: response.message,
                    })
                )
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }).catch((error) => {
                dispatch(
                    toggleToast({
                        delay: 3000,
                        bg: "danger",
                        title: error.response.data.status,
                        body: error.response.data.message,
                    })
                )
            })
    }

    return (
        <>
            {isConnected && !payAmount && <>
                <NavDropdown
                    title={
                        <span className="user-dropdown-title">
                            {!isSidebar ? "Connected" : `${(connectedAccount || "").slice(0, 4)}....${(connectedAccount || "").slice(-4)}`}
                        </span>
                    }
                    id="wallet-nav-dropdown"
                >
                    <NavDropdown.Item onClick={() => disconnectWalletAddress()}>
                        Disconnect
                    </NavDropdown.Item>
                </NavDropdown>
            </>}

            {!isConnected && <Link
                to="#"
                className="text-decoration-none text-uppercase text-white d-inline-block w-100 h-100 px-4 py-2"
                style={{
                    background: "linear-gradient(to right, #9D6BFF, #FF6CCD)",
                    outline: "none",
                    padding: !isSidebar ? "15px 50px" : "10px 50px",
                    borderRadius: "10px",
                    color: "#fff",
                    textTransform: "uppercase",
                    fontSize: !isSidebar ? "15px" : "14px",
                    borderColor: "#2e3650",
                    marginRight: !isSidebar ? "0" : "10px"
                }}
                onClick={connect}
            >
                {!isSidebar ? "Connect wallet" : "Connect"}
            </Link>}
        </>
    );
}

export default ConnectWallet;