import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToastState {
    show: boolean;
    delay: number;
    bg: string;
    title: string;
    body: string;
}

const initialState: ToastState = {
    show: false,
    delay: 3000,
    bg: 'light',
    title: '',
    body: '',
};

interface IToggleToastPayload {
    delay: number;
    bg: string;
    title: string;
    body: string;
}

const toastSlice = createSlice({
    name: 'toast',
    initialState: initialState,
    reducers: {
        toggleToast: (state, action: PayloadAction<IToggleToastPayload | null>) => {
            state.show = !state.show;
            state.delay = action.payload?.delay || 3000;
            state.bg = action.payload?.bg || 'light';
            state.title = action.payload?.title || '';
            state.body = action.payload?.body || '';
        },
    },
})

export const { toggleToast } = toastSlice.actions;
export default toastSlice.reducer;