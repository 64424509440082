import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as UserService from "../../network/services/user.service";
import { LocalStorageKeys } from "../../utils/constants";
import { IUser, IUserWithToken } from "../../common/interface";
import { clearLocalStorage } from "../../utils/utils";

interface AuthState {
    token: string | null;
    user: IUser | null;
    loading: boolean;
    isInitialized: boolean;
    isAuthModalVisible: boolean;
    isSignUpModal: boolean;
}

const initialState: AuthState = {
    token: localStorage.getItem(LocalStorageKeys.VEROX_UID),
    user: null,
    loading: true,
    isInitialized: false,
    isAuthModalVisible: false,
    isSignUpModal: false,
};

export const authenticateMe = createAsyncThunk('auth/me', async (_, { dispatch }) => {
    try {
        const user: IUser = await UserService.getUser();
        let localUser: any = localStorage.getItem(LocalStorageKeys.VEROX_USER);
        localUser = JSON.parse(localUser);
        if (!((localUser.plan === user.plan) && (localUser.expiry_date === user.expiry_date))) {
            dispatch(refreshToken());
        } else {
            localStorage.setItem(LocalStorageKeys.VEROX_TOKEN, user._id + "");
            localStorage.setItem(LocalStorageKeys.VEROX_USER_DISPLAY_NAME, user.email);
            localStorage.setItem(LocalStorageKeys.VEROX_EMAIL, user.email);
            localStorage.setItem(LocalStorageKeys.VEROX_SIGNIN, "email");
            localStorage.setItem(LocalStorageKeys.VEROX_USER, JSON.stringify(user));
        }
        return user;
    } catch (error) {
        dispatch(logout())
        throw error;
    }
})

export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, { dispatch }) => {
    try {
        const { token, ...user }: IUserWithToken = await UserService.refreshToken();
        localStorage.setItem(LocalStorageKeys.VEROX_TOKEN, user._id + "");
        localStorage.setItem(LocalStorageKeys.VEROX_UID, token + "");
        localStorage.setItem(LocalStorageKeys.VEROX_USER_DISPLAY_NAME, user.email);
        localStorage.setItem(LocalStorageKeys.VEROX_EMAIL, user.email);
        localStorage.setItem(LocalStorageKeys.VEROX_EMAIL, "email");
        localStorage.setItem(LocalStorageKeys.VEROX_USER, JSON.stringify(user));
        window.location.reload();
    } catch (error) {
        dispatch(logout())
        throw error;
    }
})

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            localStorage.setItem(LocalStorageKeys.VEROX_USER, JSON.stringify(action.payload));
            state.user = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsInitialized: (state) => {
            state.loading = false;
            state.isInitialized = true;
        },
        toggleAuthModal: (state, action: PayloadAction<void>) => {
            state.isAuthModalVisible = !state.isAuthModalVisible;
        },
        openLoginModal: (state, action: PayloadAction<void>) => {
            state.isAuthModalVisible = true;
            state.isSignUpModal = false;
        },
        openSignupModal: (state, action: PayloadAction<void>) => {
            state.isAuthModalVisible = true;
            state.isSignUpModal = true;
        },
        logout: (state) => {
            state.loading = false;
            state.user = null;
            state.isInitialized = true;
            state.token = null;
            clearLocalStorage();
            window.location.href = '/';
        },
        login: (state, action: PayloadAction<IUserWithToken>) => {
            const { token, ...user } = action.payload;

            // Update local storage
            localStorage.setItem(LocalStorageKeys.VEROX_TOKEN, user._id + "");
            localStorage.setItem(LocalStorageKeys.VEROX_UID, token + "");
            localStorage.setItem(LocalStorageKeys.VEROX_USER_DISPLAY_NAME, user.email);
            localStorage.setItem(LocalStorageKeys.VEROX_EMAIL, user.email);
            localStorage.setItem(LocalStorageKeys.VEROX_SIGNIN, "email");
            localStorage.setItem(LocalStorageKeys.VEROX_USER, JSON.stringify(user));

            // Update state
            state.user = user;
            state.isAuthModalVisible = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authenticateMe.pending, (state) => {
                state.loading = true;
            })
            .addCase(authenticateMe.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.isInitialized = true;
            })
            .addCase(authenticateMe.rejected, (state) => {
                state.loading = false;
                state.user = null;
                state.isInitialized = true;
            });
    }
})

export const { setUser, setLoading, setIsInitialized, toggleAuthModal, openLoginModal, openSignupModal, logout, login } = authSlice.actions;
export default authSlice.reducer;