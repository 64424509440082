import { axiosInstance } from "../config/adapter"
import { ApiEndPoints } from "../config/endpoints"

export const getUnreadNotificationCount = async (): Promise<number> => {
    try {
        let result: any = await axiosInstance
            .get(ApiEndPoints.user.UNREAD_NOTIFICATION_COUNT)
            .then((response) => response.data)

        return result?.count;
    } catch (e) {
        return 0;
    }
}