import "firebase/auth";

import { ConnectedProps, connect } from 'react-redux';

import React from "react";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import firebase from "firebase/app";

import "firebase/messaging";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndPoints } from "../network/config/endpoints";
import { fetchUnreadCountAsync } from "../redux/reducer/notification.reducer";
import { toggleToast } from "../redux/reducer/toast.reducer";
import withCustomRouter, { CustomRouteComponentProps } from "../common/withCustomRouter";

import { PagesRoutes } from "../common/routes";

import BaseLayout from "../layouts/BaseLayout";
import { RootState } from "../redux/reducer";
import AuthGuard from "../layouts/AuthGuard";
import ScopeGuard from "../layouts/ScopeGuard";

// Updated Imports added React.Lazy

const Markets = React.lazy(() => import("./pages/markets"))
const AboutUs = React.lazy(() => import("./pages/static/about-us"))
const AdminCalls = React.lazy(() => import("./pages/admin-calls"))
const Nfts = React.lazy(() => import("./pages/nfts"))
const Alerts = React.lazy(() => import("./pages/alerts"))
const BiggestVolumeChanges = React.lazy(() => import("./pages/biggest-volume-changes"))
const CoinDetail = React.lazy(() => import("./pages/coin-details"))
const AppCoinDetail = React.lazy(() => import("./pages/app-coin-details"))
const NftsDetail = React.lazy(() => import("./pages/nfts-details"))
const CookiePolicy = React.lazy(() => import("./pages/static/cookie-policy"))
const Dashboard = React.lazy(() => import("./pages/dashboard"))
const Disclaimer = React.lazy(() => import("./pages/static/disclaimer"))
const FAQ = React.lazy(() => import("./pages/faq"))
const LongTermInvestments = React.lazy(() => import("./pages/long-term-investments"))
const MostMentioned = React.lazy(() => import("./pages/most-mentioned"))
const News = React.lazy(() => import("./pages/news"))
const Notifications = React.lazy(() => import("./pages/notifications"))
const PaymentPlan = React.lazy(() => import("./pages/paymentplan"))
const PrivacyPolicy = React.lazy(() => import("./pages/static/privacy-policy"))
const ScoreAnalyser = React.lazy(() => import("./pages/score-analyzer"))
// const ScoreAnalyserDetail = React.lazy(() => import("./pages/score-analyser-detail"))
const TermsOfUse = React.lazy(() => import("./pages/static/terms-of-use"))
const TopPicks = React.lazy(() => import("./pages/top-picks"))
const TradingBot = React.lazy(() => import("./pages/trading-bot"))
const PassiveIncome = React.lazy(() => import("./pages/passive-income"))
const MyFavourites = React.lazy(() => import("./pages/my-favourites"))
const Settings = React.lazy(() => import("./pages/settings"))
const VRXChartDataFeedTest = React.lazy(() => import("./pages/vrx-chart-data-feed-test"))
const RecentSignals = React.lazy(() => import("./pages/recent-signals"))
const ResetPassword = React.lazy(() => import("./pages/reset-password"))
const ChangePassword = React.lazy(() => import("./pages/change-password"))
const VerifyOTP = React.lazy(() => import("./pages/verify-otp"))
const LowCapGems = React.lazy(() => import("./pages/low-cap-gems"))
const PlayToEarn = React.lazy(() => import("./pages/play-to-earn"))
const HotPresales = React.lazy(() => import("./pages/hot-presales"))
const AirdropOpportunities = React.lazy(() => import("./pages/airdrop-opportunities"))

interface AppProps extends CustomRouteComponentProps, PropsFromRedux {

}

// Outermost component which contains the Sidebar, footer and other pages based on the route
class App extends React.Component<AppProps> {

  constructor(props: AppProps) {
    super(props);

    if (!firebase.apps.length) {
      // Your web app's Firebase configuration
      var firebaseConfig = {
        apiKey: "AIzaSyDlBSWROKMKmC9saAXRe9BkoGnh8Uf-URk",
        authDomain: "verox-41537.firebaseapp.com",
        projectId: "verox-41537",
        storageBucket: "verox-41537.appspot.com",
        messagingSenderId: "437685966979",
        appId: "1:437685966979:web:f898988d18d048b71aa3b6",
        measurementId: "G-YK5WM8E7NP",
      };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
    }
  }

  fetchUnreadNotificationCount = () => {
    // Access dispatch through props
    this.props.fetchUnreadCountAsync();
  }

  componentDidMount() {

    if (this.props.user) {
      // Call fetch un-read notification count
      this.fetchUnreadNotificationCount();
    }

    let messaging: any = null;
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
      if (this.props.user) {
        messaging
          .getToken({
            vapidKey:
              "BIcR735h4gq3u8u_PMCzH8gfOPIMN52LyghMX5eb-w6gKlmIKeN7agm-bWPgRes8jqf3lRxc7umUHfW8Tx9DbvA",
          })
          .then((currentToken: any) => {
            if (currentToken) {
              axiosInstance
                .post(ApiEndPoints.pushToken.ADD, {
                  fcm_token: currentToken,
                  type: "web",
                })
                .then((response) => response.data)
                .then((response) => {
                  console.log("Token updated")
                })

              const onMessageListener = () =>
                new Promise((resolve) => {
                  messaging.onMessage((payload: any) => {
                    resolve(payload);
                  });
                });

              onMessageListener()
                .then((payload: any) => {
                  //setShow(true);
                  //setNotification({title: payload.notification.title, body: payload.notification.body})
                  this.props.toggleToast({
                    delay: 6000,
                    bg: 'light',
                    title: payload.notification.title,
                    body: payload.notification.body,
                  })
                  console.log("got push notification ", payload);
                })
                .catch((err) => console.log("failed: ", err));

              // Track the token -> client mapping, by sending to backend server
              // show on the UI that permission is secured
            } else {
              console.log("No registration token available. Request permission to generate one.");

              // shows on the UI that permission is required
            }
          })
          .catch((err: any) => {
            console.log("An error occurred while retrieving token. ", err);
            // catch error while creating client token
          });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Scroll to the top of the page when the route changes
      window.scrollTo(0, 0);
    }
  }

  render() {

    return (
      <>
        <div className="App">
          <Routes>
            <Route path="/" element={<Navigate to={PagesRoutes.Markets.path} />} />

            <Route element={<BaseLayout><Outlet /></BaseLayout>}>
              <Route path={PagesRoutes.Markets.path} element={<Markets />} />

              <Route path={PagesRoutes.ResetPassword.path} element={<ResetPassword />} />
              <Route path={PagesRoutes.ChangePassword.path} element={<ChangePassword />} />
              <Route path={PagesRoutes.VerifyOTP.path} element={<VerifyOTP />} />

              <Route path={PagesRoutes.FAQ.path} element={<FAQ />} />
              <Route path={PagesRoutes.AboutUs.path} element={<AboutUs />} />
              <Route path={PagesRoutes.CookiePolicy.path} element={<CookiePolicy />} />
              <Route path={PagesRoutes.Disclaimer.path} element={<Disclaimer />} />
              <Route path={PagesRoutes.PrivacyPolicy.path} element={<PrivacyPolicy />} />
              <Route path={PagesRoutes.TermsOfUse.path} element={<TermsOfUse />} />
              <Route path={PagesRoutes.PaymentPlan.path} element={<PaymentPlan />} />

              <Route element={<AuthGuard><Outlet /></AuthGuard>}>
                <Route path={PagesRoutes.CoinDetail.path} element={<CoinDetail />} />

                <Route element={<ScopeGuard><Outlet /></ScopeGuard>}>
                  <Route path={PagesRoutes.NftsDetail.path} element={<NftsDetail />} />
                  <Route path={PagesRoutes.Dashboard.path} element={<Dashboard />} />
                  <Route path={PagesRoutes.TopPicks.path} element={<TopPicks />} />
                  <Route path={PagesRoutes.MostMentioned.path} element={<MostMentioned />} />
                  <Route path={PagesRoutes.BiggestVolumeChanges.path} element={<BiggestVolumeChanges />} />
                  <Route path={PagesRoutes.PassiveIncome.path} element={<PassiveIncome />} />
                  <Route path={PagesRoutes.AdminCalls.path} element={<AdminCalls />} />
                  <Route path={PagesRoutes.Nftstowatch.path} element={<Nfts />} />
                  <Route path={PagesRoutes.Lowcapgems.path} element={<LowCapGems />} />
                  <Route path={PagesRoutes.Playtoearncoin.path} element={<PlayToEarn />} />
                  <Route path={PagesRoutes.Hotpresales.path} element={<HotPresales />} />
                  <Route path={PagesRoutes.AirdropOpportunities.path} element={<AirdropOpportunities />} />
                  <Route path={PagesRoutes.News.path} element={<News />} />
                  <Route path={PagesRoutes.LongTermCrypto.path} element={<LongTermInvestments />} />
                  <Route path={PagesRoutes.AutomatedTrade.path} element={<TradingBot />} />
                  <Route path={PagesRoutes.RecentSignals.path} element={<RecentSignals />} />
                  <Route path={PagesRoutes.MyAlerts.path} element={<Alerts />} />
                </Route>

                <Route path={PagesRoutes.ScoreAnalyser.path} element={<ScoreAnalyser />} />
                <Route path={PagesRoutes.MyFavourites.path} element={<MyFavourites />} />
                <Route path={PagesRoutes.Notifications.path} element={<Notifications />} />
                <Route path={PagesRoutes.Settings.path} element={<Settings />} />
              </Route>
            </Route>

            <Route path={PagesRoutes.IFrameCoinDetail.path} element={<AppCoinDetail />} />

            <Route path={PagesRoutes.VRXChartDataFeedTest.path} element={<VRXChartDataFeedTest />} />
            <Route path={PagesRoutes.VRXChartDataFeedTestID.path} element={<VRXChartDataFeedTest />} />
          </Routes>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

const mapDispatchToProps = {
  fetchUnreadCountAsync,
  toggleToast,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withCustomRouter(App));
