import { Link, useLocation } from "react-router-dom";
import { Badge, Nav, Image } from "@themesberg/react-bootstrap";

import ScopedAccess from "../../common/ScopedAccess";
import { ScopeTypes } from "../../utils/constants";
import { PagesRoutes } from "../../common/routes";

import { FaBullseye, FaFireAlt, FaHandHoldingUsd, FaQuestionCircle, FaReply, FaRobot, FaRss, FaSignal, FaStar, FaUsers } from 'react-icons/fa';
import { FaSearchDollar, FaPoll } from "react-icons/fa"
import { RiNftLine } from "react-icons/ri"
import { PiGameController } from "react-icons/pi"
import { GiAirBalloon, GiCoins } from "react-icons/gi"
import { IoDiamondOutline } from 'react-icons/io5';

function NavItem(props: any) {

    const location = useLocation();

    const {
        title,
        link,
        target,
        icon,
        iconOther,
        image,
        badgeText,
        badgeBg = "secondary",
        badgeColor = "primary",
        iconClassNames,
        onClick
    } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = location.pathname === link ? "active" : "";
    return (
        <>
            <Nav.Item className={navItemClassName} onClick={onClick}>
                <Nav.Link as={Link} to={link} target={target} className={classNames}>
                    <span >
                        {icon ? (
                            <span className={`sidebar-icon ${iconClassNames || ""}`} >
                                {icon}
                                {/* <i>{icon}</i> */}
                                {/* <i className={icon}></i>{" "} */}
                            </span>
                        ) : null}
                        {iconOther ? (
                            <span className="sidebar-icon">
                                <i className="fa fa-search"></i>{" "}
                            </span>
                        ) : null}
                        {image ? (
                            <Image
                                src={image}
                                width={20}
                                height={20}
                                className="sidebar-icon svg-icon"
                            />
                        ) : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge
                            pill
                            bg={badgeBg}
                            text={badgeColor}
                            className="badge-md notification-count ms-2"
                        >
                            {badgeText}
                        </Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        </>
    );
}

const MenuItems = ({ plan = "ultimate", BrandComponent, toggleSidebar }: any) => {

    return <Nav className="flex-column pt-3 pt-md-0">

        {
            BrandComponent ? <>{BrandComponent}</> : null
        }

        <ScopedAccess plan={plan} scopeType={ScopeTypes.MARKETS}>
            <NavItem
                title="Markets"
                link={PagesRoutes.Markets.path}
                icon={<FaSearchDollar />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.DASHBOARD}>
            <NavItem
                title="Dashboard"
                link={PagesRoutes.Dashboard.path}
                icon={<FaPoll />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.TOP_PICKS}>
            <NavItem
                title="Top Picks"
                link={PagesRoutes.TopPicks.path}
                icon={<FaStar />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.MOST_MENTIONED}>
            <NavItem
                title="Most Mentioned"
                link={PagesRoutes.MostMentioned.path}
                icon={<FaFireAlt />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.BIGGEST_VOLUME}>
            <NavItem
                title="Biggest Volume Changes"
                link={PagesRoutes.BiggestVolumeChanges.path}
                icon={<FaReply />}
                iconClassNames="volume-icon-rotate"
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        {/* <NavItem
        title="Favourites"
        link={PagesRoutes.MyFavourites.path}
        icon="fa fa-star"
      /> */}

        {/* <NavItem
        title="Alerts"
        link={PagesRoutes.MyAlerts.path}
        icon="fa fa-bell"
      /> */}

        <ScopedAccess plan={plan} scopeType={ScopeTypes.PASSIVE_INCOME}>
            <NavItem
                title="Passive Income"
                link={PagesRoutes.PassiveIncome.path}
                icon={<FaBullseye />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        {/* <NavItem
        title="Score Analyzer"
        link={PagesRoutes.ScoreAnalyser.path}
        icon="fa fa-tachometer-alt"
      /> */}

        <ScopedAccess plan={plan} scopeType={ScopeTypes.ADMIN_CALLS}>
            <NavItem
                title="Admin Calls"
                link={PagesRoutes.AdminCalls.path}
                icon={<FaUsers />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>
        <ScopedAccess plan={plan} scopeType={ScopeTypes.NFTS} >
            <NavItem
                title="NFTs"
                link={PagesRoutes.Nftstowatch.path}
                icon={<RiNftLine />}
            />
        </ScopedAccess>
        <ScopedAccess plan={plan} scopeType={ScopeTypes.LOW_CAP_GEMS}>
            <NavItem
                title="Low Cap Gems"
                link={PagesRoutes.Lowcapgems.path}
                icon={<IoDiamondOutline />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            // icon="fa fa-users"
            />
        </ScopedAccess>
        <ScopedAccess plan={plan} scopeType={ScopeTypes.PLAY_TO_EARN}>
            <NavItem
                title="Play To Earn Coins"
                link={PagesRoutes.Playtoearncoin.path}
                icon={<PiGameController />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>
        <ScopedAccess plan={plan} scopeType={ScopeTypes.HOT_PRESALES}>
            <NavItem
                title="Hot Presales"
                link={PagesRoutes.Hotpresales.path}
                icon={<GiCoins />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>
        <ScopedAccess plan={plan} scopeType={ScopeTypes.AIRDROP_OPPORTUNITIES}>
            <NavItem
                title="Airdrop Opportunities"
                link={PagesRoutes.AirdropOpportunities.path}
                icon={<GiAirBalloon />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>
        <ScopedAccess plan={plan} scopeType={ScopeTypes.NEWS}>
            <NavItem
                title="News"
                link={PagesRoutes.News.path}
                icon={<FaRss />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.LONG_TERM}>
            <NavItem
                title="Best Long Term Crypto Investments"
                link={PagesRoutes.LongTermCrypto.path}
                icon={<FaHandHoldingUsd />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.TRADING_BOT}>
            <NavItem
                title="Automated Trading Bot"
                link={PagesRoutes.AutomatedTrade.path}
                icon={<FaRobot />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.RECENT_SIGNALS}>
            <NavItem
                title="Recent Signals"
                link={PagesRoutes.RecentSignals.path}
                icon={<FaSignal />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>

        <ScopedAccess plan={plan} scopeType={ScopeTypes.FAQ}>
            <NavItem
                title="FAQ"
                link={PagesRoutes.FAQ.path}
                icon={<FaQuestionCircle />}
                onClick={(e: any) => {
                    e.stopPropagation();
                    toggleSidebar && toggleSidebar()
                }}
            />
        </ScopedAccess>
    </Nav>
}

export default MenuItems;