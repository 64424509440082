import { combineReducers } from "redux";
import notificationReducer from "./notification.reducer";
import authReducer from "./auth.reducer";
import toastReducer from "./toast.reducer";

const reducer = combineReducers({
    notifications: notificationReducer,
    auth: authReducer,
    toast: toastReducer,
})

export type RootState = ReturnType<typeof reducer>;

export default reducer;