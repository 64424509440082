// ** React Imports
import React, { ReactNode, useEffect } from 'react'

// ** Next Imports
import { useNavigate } from 'react-router-dom'
import { PagesRoutes } from '../common/routes'
import { useDispatch } from 'react-redux';
import { toggleAuthModal } from '../redux/reducer/auth.reducer';
import { LocalStorageKeys } from '../utils/constants';

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = props => {

    const { children } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(
        () => {
            if (!localStorage.getItem(LocalStorageKeys.VEROX_UID)) {
                navigate(PagesRoutes.Markets.path);
                dispatch(toggleAuthModal());
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return <>{children}</>
}

export default AuthGuard
