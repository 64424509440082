// ** Overrides Imports
import MuiDialog from './dialog'

const Overrides = (theme: any, settings: any) => {
    const { skin } = settings

    const dialog = MuiDialog(theme, skin)

    return Object.assign(
        dialog,
    )
}

export default Overrides
