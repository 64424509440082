import { LocalStorageKeys } from "./constants";

export const clearLocalStorage = () => {
    localStorage.removeItem(LocalStorageKeys.VEROX_TOKEN);
    localStorage.removeItem(LocalStorageKeys.VEROX_UID);
    localStorage.removeItem(LocalStorageKeys.VEROX_USER_DISPLAY_NAME);
    localStorage.removeItem(LocalStorageKeys.VEROX_EMAIL);
    localStorage.removeItem(LocalStorageKeys.VEROX_SIGNIN);
    localStorage.removeItem(LocalStorageKeys.VEROX_USER);
}

export function extractOHLCData(ohlcData: any) {
    if (!(ohlcData?.length > 0)) return { open: 0, high: 0, low: 0, close: 0 };

    // Initialize variables to track open, high, low, and close
    let open = ohlcData[0][1];
    let high = ohlcData[0][2];
    let low = ohlcData[0][3];
    let close = ohlcData[ohlcData.length - 1][4]; // Close is the last value

    // Iterate through the data to find the highest high and lowest low
    ohlcData.forEach(([timestamp, o, h, l, c]: any) => {
        high = Math.max(high, h);
        low = Math.min(low, l);
    });

    return { open, high, low, close };
}

export function PercentageValue(number: number) {
    const numberPercentage = (number * 100) / 10000;
    return numberPercentage
}