import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMatchedRoute } from '../common/useMatchedRoute';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducer';
import { isScopeHigher } from '../common/ScopedAccess';
import { PagesRoutes } from '../common/routes';

interface ScopeGuardProps {
    children: ReactNode;
}

const ScopeGuard: React.FC<ScopeGuardProps> = ({ children }) => {

    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();

    const currentPath: any = useMatchedRoute();

    useEffect(() => {
        if (isScopeHigher(user?.plan, currentPath.scopeType)) {
            navigate(PagesRoutes.Markets.path);
        }
    }, [currentPath.scopeType, navigate, user?.plan])

    return <>{children}</>;
};

export default ScopeGuard;