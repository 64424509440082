export const AUTH_API_URL = "https://auth.veroxai.io";
// export const AUTH_API_URL = "http://localhost:8020";
export const COIN_FEED_API_URL = "https://coinfeed.veroxai.io";
// export const COIN_FEED_API_URL = "http://localhost:8020";
export const UDF_API_URL = "https://udf.veroxai.io";
// export const UDF_API_URL = "http://192.168.0.138:8020";
export const UDF_API_URL_TEST = "https://test-udf.veroxai.io";
// export const UDF_API_URL_TEST = "http://192.168.0.138:8020";
export const BINANCE_API_URL = "https://trading-bot.veroxai.io";
// export const BINANCE_API_URL = "http://localhost:8020";

export const ApiEndPoints = {
    auth: {
        LOGIN: `${AUTH_API_URL}/auth`,
        REGISTER: `${AUTH_API_URL}/register`,
        SOCKET: `${AUTH_API_URL}`,
        REFRESH_TOKEN: `${AUTH_API_URL}/auth/refresh/token`,
        CHANGE_PASSWORD: `${AUTH_API_URL}/change/password`,
        UPDATE_PASSWORD: `${AUTH_API_URL}/update/password`,
        RESET: `${AUTH_API_URL}/reset`,
        FORGOT: `${AUTH_API_URL}/forgot`,
        VERIFY: `${AUTH_API_URL}/verify`,
        MEMBERSHIP_PLAN: `${AUTH_API_URL}/membership/plans`,
    },
    user: {
        GET_DETAILS: `${AUTH_API_URL}/profile`,
        UPDATE_BOT_SETTINGS: `${AUTH_API_URL}/update/bot/settings`,
        UPDATE_BOT_START: `${AUTH_API_URL}/update/bot/start`,
        UPDATE_BOT_STOP: `${AUTH_API_URL}/update/bot/stop`,
        ACTIVE_TRADE: `${AUTH_API_URL}/active-trade`,
        NOTIFICATIONS: `${AUTH_API_URL}/notifications`,
        READ_NOTIFICATIONS: `${AUTH_API_URL}/notifications/read`,
        UNREAD_NOTIFICATION_COUNT: `${AUTH_API_URL}/notifications/unread/count`,
        CLEAR_NOTIFICATIONS: `${AUTH_API_URL}/notifications/clear`,
    },
    favourites: {
        TOGGLE: (coin: string) => `${AUTH_API_URL}/favourites/toggle/${coin}`
    },
    coinfeed: {
        WATCH_LIST: `${COIN_FEED_API_URL}/watchlist`,
        MARKETS: `${COIN_FEED_API_URL}/coins/markets`,
        VRX_USD: `${COIN_FEED_API_URL}/vrx-usd`,
        COIN_PRICE: (id: string, currency = "usd") => `${COIN_FEED_API_URL}/price/${id}/${currency}`,
        COINS_MARKETS: `${COIN_FEED_API_URL}/coins/markets`,
        ALGO_ACCURACY: (coin: string) => `${COIN_FEED_API_URL}/algo/accuracy/${coin}`,
        TOP_PICKS: `${COIN_FEED_API_URL}/top-picks`,
        ADMIN_CALLS: `${COIN_FEED_API_URL}/admin-calls`,
        NFTS: `${COIN_FEED_API_URL}/misc/nfts`,
        NFTS_BY_ID: (contractAddress: string) => `${COIN_FEED_API_URL}/misc/nfts/${contractAddress}`,
        NFTS_TO_MINT: `${COIN_FEED_API_URL}/misc/nfts-to-mint`,
        NFTS_TO_MINT_BY_ID: (contractAddress: string) => `${COIN_FEED_API_URL}/misc/nfts-to-mint/${contractAddress}`,
        UPCOMING_NFTS: `${COIN_FEED_API_URL}/misc/upcoming-nfts`,
        LCG: `${COIN_FEED_API_URL}/misc/lcg`,
        AIRDROP: `${COIN_FEED_API_URL}/misc/airdrop`,
        PLAYTOEARN: `${COIN_FEED_API_URL}/misc/pte`,
        UOCOMINGICO: `${COIN_FEED_API_URL}/misc/upcoming-ico`,
        ACTIVEICO: `${COIN_FEED_API_URL}/misc/active-ico`,
        GLOBAL_METRICS: `${COIN_FEED_API_URL}/global-metrics`,
        BULL_BEAR: (coinId: string) => `${COIN_FEED_API_URL}/bull-bear/${coinId}`,
        COIN_INFO: (coinId: string) => `${COIN_FEED_API_URL}/coins/${coinId}/info`,
        TOP_GAINERS: `${COIN_FEED_API_URL}/coins/top-gainers`,
        TOP_LOSERS: `${COIN_FEED_API_URL}/coins/top-losers`,
        GET_PERFORMING_COINS: `${COIN_FEED_API_URL}/misc/top-performing-coins`
    },
    signalAlert: {
        LIST: `${AUTH_API_URL}/signal-alerts/`,
        TOGGLE: `${AUTH_API_URL}/signal-alerts/toggle`,
    },
    priceAlert: {
        LIST: `${AUTH_API_URL}/price-alerts/`,
        ADD: `${AUTH_API_URL}/price-alerts/`,
        DELETE: `${AUTH_API_URL}/price-alerts/delete`,
    },
    signals: {
        RECENT_SIGNALS: `${COIN_FEED_API_URL}/recent-signals`,
    },
    pushToken: {
        ADD: `${AUTH_API_URL}/update/push-token`,
    },
    wallet: {
        UPDATE: `${AUTH_API_URL}/update/wallet`,
        DISCONNECT: `${AUTH_API_URL}/disconnect/wallet`,
    },
    udf: {
        DEFAULT: `${UDF_API_URL}`,
        DEFAULT_TEST: `${UDF_API_URL_TEST}`,
    },
    binance: {
        VERIFY: `${BINANCE_API_URL}/binance/verify`
    },

}