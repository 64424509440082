import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FallbackSpinner from '../components/shared/fallbackSpinner';
import { RootState } from '../redux/reducer';
import { authenticateMe, setIsInitialized, toggleAuthModal } from '../redux/reducer/auth.reducer';
import { AnyAction } from 'redux';
import { Image, Modal } from '@themesberg/react-bootstrap';
import Auth from "../components/pages/auth"

import VeroxLogo from "../assets/images/logo.png";

interface AuthProviderProps {
    children: ReactNode;
}

function AuthProvider(props: AuthProviderProps) {

    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.auth.token);
    const loading = useSelector((state: RootState) => state.auth.loading);
    const isInitialized = useSelector((state: RootState) => state.auth.isInitialized);
    const isAuthModalVisible = useSelector((state: RootState) => state.auth.isAuthModalVisible);

    useEffect(() => {
        if (token) {
            dispatch(authenticateMe() as unknown as AnyAction);
        } else {
            dispatch(setIsInitialized());
        }
    }, [dispatch, token])

    if (loading || !isInitialized) return <FallbackSpinner />

    return <>
        {props.children}

        <Modal
            className="auth-modal"
            centered
            show={isAuthModalVisible}
            backdrop="static"
            onHide={() => {
                dispatch(toggleAuthModal());
            }}
        >
            <Modal.Header closeButton>
                <Image src={VeroxLogo} />
            </Modal.Header>
            <Modal.Body>
                <Auth />
            </Modal.Body>
        </Modal>

    </>
}

export default AuthProvider;