import { ReactNode, useEffect, useState } from 'react';
import { APP_VERSION, LocalStorageKeys } from '../utils/constants';
import FallbackSpinner from '../components/shared/fallbackSpinner';

interface VersionGuardProps {
    children: ReactNode;
}

function VersionGuard(props: VersionGuardProps) {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAppVersion = () => {
            let currentAppVersion = localStorage.getItem(LocalStorageKeys.VEROX_APP_VERSION);
            if (currentAppVersion !== APP_VERSION) {
                localStorage.clear();
                localStorage.setItem(LocalStorageKeys.VEROX_APP_VERSION, APP_VERSION);
                window.location.reload();
            } else {
                setLoading(false);
            }
        }
        checkAppVersion();
    }, [])

    if (loading) return <FallbackSpinner />

    return <>
        {props.children}
    </>
}

export default VersionGuard;