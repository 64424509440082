import { Alert, Button, Form } from "@themesberg/react-bootstrap";
import React from "react";
import "react-phone-number-input/style.css";
import { Link, NavLink } from "react-router-dom";
import { axiosInstance } from "../../network/config/adapter";
import { ApiEndPoints } from "../../network/config/endpoints";
import { PagesRoutes } from "../../common/routes";
import withCustomRouter, { CustomRouteComponentProps } from "../../common/withCustomRouter";
import { login, toggleAuthModal } from "../../redux/reducer/auth.reducer"
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../redux/reducer";

interface AuthState {
  email: string;
  password: string;
  password2: string;
  mobile: string;
  mobilecode: string;
  otp: string;
  isEmailSignInVisible: boolean;
  isMobileSignInVisible: boolean;
  isEmailSignUpVisible: boolean;
  errorMessage: string;
  successMessage: string;
  isChecked: boolean;
  reRef: any;
  name: any;
}

interface AuthProps extends CustomRouteComponentProps, PropsFromRedux {

}

class Auth extends React.Component<AuthProps, AuthState> {

  constructor(props: AuthProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password2: "",
      mobile: "",
      mobilecode: "",
      otp: "",
      isEmailSignInVisible: false,
      isMobileSignInVisible: false,
      isEmailSignUpVisible: false,
      errorMessage: "",
      successMessage: "",
      isChecked: false,
      reRef: { s: "s" },
      name: "",
    };
  }

  componentDidMount() {
    if (this.props.isSignUpModal) {
      this.showSignUp();
    }
  }

  componentDidUpdate(prevProps: Readonly<AuthProps>, prevState: Readonly<AuthState>, snapshot?: any): void {
    if ((prevProps.isSignUpModal !== this.props.isSignUpModal) && this.props.isSignUpModal) {
      this.showSignUp();
    }
  }

  signUpWithUsernamePassword() {
    this.setState({ errorMessage: "", successMessage: "" });
    if (this.state.password !== this.state.password2) {
      this.setState({
        errorMessage: "Passwords do not match. please type again",
      });
      return;
    } else if (!this.state.isChecked) {
      this.setState({ errorMessage: "Please accept terms and conditions" });
      return;
    }

    let payload = {
      email: this.state.email,
      password: this.state.password,
    }

    axiosInstance
      .post(ApiEndPoints.auth.REGISTER, payload)
      .then((result) => {
        this.setState({ successMessage: "Verification Email has been sent to " + this.state.email });
        setTimeout(() => {
          this.setState({ successMessage: "" });
        }, 4000);
        sessionStorage.setItem("verificationMail", this.state.email);
        this.props.navigate(PagesRoutes.VerifyOTP.path);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ errorMessage: error.response?.data?.message || error.message });
      });
  }

  signInWithUsernamePassword() {
    this.setState({ errorMessage: "", successMessage: "" });

    let payload = {
      email: this.state.email,
      password: this.state.password,
    }
    axiosInstance
      .post(ApiEndPoints.auth.LOGIN, payload)
      .then((result: any) => {
        if (result.data.status === "error") {
          this.setState({ successMessage: `Verification Email has been sent to ${this.state.email}` });
          setTimeout(() => {
            this.setState({ successMessage: "" });
          }, 4000);
          sessionStorage.setItem("verificationMail", this.state.email);
          this.props.navigate(PagesRoutes.VerifyOTP.path);
          window.location.reload();
        } else {
          this.props.login({ ...result.data.data, token: result.data.token });
          this.props.navigate(PagesRoutes.Dashboard.path);
          window.location.reload();
        }
      })
      .catch((error: any) => {
        console.log(error.response);
        this.setState({ errorMessage: error.response.data.message });
      });
  }

  showSignInOptions() {
    this.setState({
      isEmailSignInVisible: false,
      isMobileSignInVisible: false,
      isEmailSignUpVisible: false,
      errorMessage: "",
      successMessage: "",
    });
  }

  showSignUp() {
    this.setState({
      isEmailSignInVisible: false,
      isMobileSignInVisible: false,
      isEmailSignUpVisible: true,
      errorMessage: "",
      successMessage: "",
    });
  }

  showSignInWithUsernamePassword() {
    this.setState({
      isEmailSignInVisible: true,
      isMobileSignInVisible: false,
      isEmailSignUpVisible: false,
      errorMessage: "",
      successMessage: "",
    });
  }

  render() {
    return (
      <>
        <div id="captchas-container"></div>

        {!this.state.isEmailSignInVisible &&
          !this.state.isMobileSignInVisible &&
          !this.state.isEmailSignUpVisible && (
            <>
              <h4>Login to VeroxAI</h4>
              <div className="buttonDiv">
                <div className="login-card">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  />{" "}
                </div>
                <br />
                <div className="login-card">
                  {" "}
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                  />
                </div>
                <br />
                <div className="login-card">
                  <Button
                    style={{ border: "none" }}
                    onClick={() => this.signInWithUsernamePassword()}
                  >
                    Sign In
                  </Button>{" "}
                </div>
                <Link
                  to="/reset-password"
                  style={{
                    color: "rgb(19,199,255)",
                    display: "inline-block",
                    margin: "10px 0",
                  }}
                  onClick={() => {
                    this.props.toggleAuthModal();
                  }}
                >
                  Forgot Password?
                </Link>
                <br /> <br />
                <Button variant="link" onClick={() => this.showSignUp()}>
                  Don&apos;t have an account? <span
                    style={{
                      color: 'rgb(19, 199, 255)',
                      // textDecoration: 'underline'
                    }}
                  >
                    Sign Up
                  </span>
                </Button>
              </div>
            </>
          )}

        {this.state.isEmailSignUpVisible && (
          <>
            <h4>Create Account</h4>
            <div className="buttonDiv">
              <div className="login-card">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                />
              </div>
              <br />
              <div className="login-card">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                />
              </div>
              <br />
              <div className="login-card">
                {" "}
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(event) =>
                    this.setState({ password2: event.target.value })
                  }
                />
              </div>
              <Form.Group
                className="mt-4 d-inline-flex"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  onChange={(event) =>
                    this.setState({ isChecked: event.target.checked })
                  }
                  type="checkbox"
                  label=""
                />
                <div className="terms">
                  I agree to the{" "}
                  <NavLink
                    to="/terms-of-service"
                    // variant="link"
                    onClick={() => {
                      this.props.toggleAuthModal();
                    }}
                    style={{
                      color: 'rgb(19, 199, 255)',
                      textDecoration: 'underline'
                    }}
                  >
                    Terms of Condition
                  </NavLink>
                </div>
              </Form.Group>
              <br /> <br />
              <div className="login-card">
                {" "}
                <Button
                  style={{ border: "none" }}
                  onClick={() => this.signUpWithUsernamePassword()}
                >
                  Sign Up
                </Button>
              </div>{" "}
              <br /> <br />
              <Button variant="link" onClick={() => this.showSignInOptions()}>
                Already have an account? <span
                  style={{
                    color: 'rgb(19, 199, 255)',
                    // textDecoration: 'underline'
                  }}
                >
                  Sign In
                </span>
              </Button>
              <br />
              <Button variant="link" onClick={() => this.showSignInOptions()}>
                Back to Login options
              </Button>
            </div>
          </>
        )}

        {this.state.errorMessage && (
          <Alert variant="danger">{this.state.errorMessage}</Alert>
        )}
        {this.state.successMessage && (
          <Alert variant="success">{this.state.successMessage}</Alert>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  isSignUpModal: state.auth.isSignUpModal,
})

const mapDispatchToProps = {
  login,
  toggleAuthModal
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withCustomRouter(Auth));
