import { ScopeTypes } from "../utils/constants";

export const PagesRoutes = {
    // pages   
    FAQ: { path: "/faq" },
    Markets: { path: "/markets" },
    ResetPassword: { path: "/reset-password" },
    ChangePassword: { path: "/change-password" },
    VerifyOTP: { path: "/verify-otp" },
    TopPicks: { path: "/toppicks", scopeType: ScopeTypes.TOP_PICKS },
    MostMentioned: { path: "/most-mentioned", scopeType: ScopeTypes.MOST_MENTIONED },
    BiggestVolumeChanges: { path: "/biggest-volume-changes", scopeType: ScopeTypes.BIGGEST_VOLUME },
    PassiveIncome: { path: "/passive-income", scopeType: ScopeTypes.PASSIVE_INCOME },
    ScoreAnalyser: { path: "/score-analyser" },
    ScoreAnalyserDetail: { path: "/score-analyser-detail/:coinid/:coinname" },
    MyFavourites: { path: "/favourites" },
    MyAlerts: { path: "/alerts", scopeType: ScopeTypes.ALERTS },
    TermsOfUse: { path: "/terms-of-service" },
    Disclaimer: { path: "/disclaimer" },
    PrivacyPolicy: { path: "/privacy-policy" },
    AboutUs: { path: "/about-us" },
    CookiePolicy: { path: "/cookie-policy" },
    Dashboard: { path: "/dashboard", scopeType: ScopeTypes.DASHBOARD },
    News: { path: "/news", scopeType: ScopeTypes.NEWS },
    CoinDetail: { path: "/coin/:coinid/:coinname" },
    NftsDetail: { path: "/nft/:nftType/:contractAddress", scopeType: ScopeTypes.NFTS },
    Settings: { path: "/settings" },
    NotFound: { path: "/examples/404" },
    LongTermCrypto: { path: "/long-term-crypto-investment", scopeType: ScopeTypes.LONG_TERM },
    AdminCalls: { path: "/admin-calls", scopeType: ScopeTypes.ADMIN_CALLS },
    Nftstowatch: { path: "/nfts", scopeType: ScopeTypes.NFTS },
    Playtoearncoin: { path: "/play-to-earn-coin", scopeType: ScopeTypes.PLAY_TO_EARN },
    Hotpresales: { path: "/hot-presales", scopeType: ScopeTypes.HOT_PRESALES },
    AirdropOpportunities: { path: "/airdrop-opportunities", scopeType: ScopeTypes.AIRDROP_OPPORTUNITIES },
    Lowcapgems: { path: "/low-cap-gems", scopeType: ScopeTypes.LOW_CAP_GEMS },
    AutomatedTrade: { path: "/automated-trading", scopeType: ScopeTypes.TRADING_BOT },
    RecentSignals: { path: "/recent-signals", scopeType: ScopeTypes.RECENT_SIGNALS },
    Notifications: { path: "/notifications" },
    PaymentPlan: { path: "/paymentplan" },
    VRXChartDataFeedTest: { path: "/vrx-chart-data-feed-test" },
    VRXChartDataFeedTestID: { path: "/vrx-chart-data-feed-test/:symbol" },
    IFrameCoinDetail: { path: "/app/coin/:coinid/:coinname" },
};