import { ThemeProvider, createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils'

import overrides from '../common/components/theme/overrides'
import typography from '../common/components/theme/typography'

const defaultTheme = {
    palette: {
        primary: {
            main: '#262D44',
        },
        secondary: {
            main: '#61DAFB',
        },
        error: {
            main: '#FA5252',
        },
        warning: {
            main: '#FF9F89',
        },
        info: {
            main: '#1B998B',
        },
        success: {
            main: '#05A677',
        },
        text: {
            primary: '#262D44',
            secondary: '#66799e',
        },
        background: {
            default: '#F0F3F6',
            paper: '#ffffff',
        },
        grey: {
            100: '#f3f7fa',
            200: '#1A202C',
            300: '#F0F3F6',
            400: '#eaedf2',
            500: '#d1d7e0',
            600: '#93a5be',
            700: '#66799e',
            800: '#506690',
            900: '#4A5073',
            A100: '#93a5be',
            A200: '#506690',
            A400: '#262D44',
            A700: '#1A202C',
        },
    },
    typography: {
        fontFamily: 'euclidcircular, sans-serif',
        fontSize: 16, // Default font size in pixels
    },
    spacing: 8, // Default spacing unit in pixels
}

function ThemeComponent({ children }: any) {

    // ** Merged ThemeOptions of Core and User
    const coreThemeConfig = defaultTheme

    // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
    let theme = createTheme(coreThemeConfig)

    // ** Deep Merge Component overrides of core and user
    const mergeComponentOverrides = (theme: any, settings: any) =>
        deepmerge({ ...overrides(theme, settings) }, {})

    // ** Deep Merge Typography of core and user
    const mergeTypography = (theme: any) => deepmerge(typography(theme), {})

    // ** Continue theme creation and pass merged component overrides to CreateTheme function
    theme = createTheme(theme, {
        components: { ...mergeComponentOverrides(theme, {}) },
        typography: { ...mergeTypography(theme) }
    })

    return <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>;
}

export default ThemeComponent